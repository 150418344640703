module.exports = {
  pathutility: {
    pagename: 'pathutility',
    name: 'Path Utility Construction',
    description:
      'Construction company single page website developed with ReactJS, React Router V4, React Slick Carousel and React Google Maps. Responsible for full development-designed by Designsteins.com.',
    livelink: 'https://pathutility.com',
    github: null,
    img: 'thumb-pathutility.png',
    fullimg: 'website-pathutility.png'
  },
  designsteins: {
    pagename: 'designsteins',
    name: 'Designsteins 2018',
    description:
      'Design company website developed with ReactJS, React Router V4, React Slick Carousel and React Google Maps. Responsible for full development-designed by Designsteins.com. Rebuilt in 2019 by myself as a WordPress theme.',
    livelink: null,
    github: null,
    img: 'thumb-designsteins.png',
    fullimg: 'website-designsteins.png'
  },
  modmemes: {
    pagename: 'modmemes',
    name: 'modmemes',
    description:
      'Meme Library application built with React/Redux, ExpressJS, NodeJS and MongoDB.  Features include theme options, viewable images/gifs, one-click link copying and tag filtering.',
    livelink: 'https://modmemes.herokuapp.com',
    github: 'https://github.com/vannya/modmemes',
    img: 'modmemes.jpg',
    fullimg: 'website-modmemes.png'
  },
  chingu: {
    pagename: 'chingu',
    name: 'Chingu Developer Network',
    description:
      'Original front end development of the Chingu Developer Network client using React and interfacing with a Node / GraphQL / PostgreSQL backend. Responsible for full front end development at the time.  Image show my build from their designs.',
    // livelink: 'https://chingu.io',
    // github: 'https://github.com/chingu-x/chingu-frontend',
    img: 'chingu.jpg',
    fullimg: 'website-chingu.png'
  },
  portfolio2017: {
    pagename: 'portfolio2017',
    name: 'Previous Portfolio Website',
    description:
      'Previous portfolio website built as a single-page landing application with hidden routed pages revealing my resume. Developed with ReactJS, Sass, React Router V4 and a firebase backend for storing my daily log. Replaced in 2018, mainly to try a new build technique rather than a new design.',
    livelink: null,
    github: 'https://github.com/vannya/vannya-portfolio',
    img: 'thumb-vannya-me.png',
    fullimg: 'website-vannya-me.png'
  },
  junipermail: {
    pagename: 'junipermail',
    name: 'JuniperMail',
    description:
      'Survey creation application with a MERN framework.  Features include Stripe integration, Sendgrid integration (for webhooks), survey metrics and email templates for quick setup.',
    // livelink: 'https://junipermail.herokuapp.com',
    // github: 'https://github.com/vannya/junipermail',
    img: 'junipermail.jpg',
    fullimg: 'junipermail.jpg'
  },
  // bearbnb: {
  //   pagename: 'bearbnb',
  //   name: 'BearBnb',
  //   description:
  //     'A clone of the popular site Airbnb using MERN stack developed as a 4 person remote team.  Responsible for entire backend, database structure, application architecture, OAuth implementation and user admin pages.  Additionally, I created the navigation and main overview sections of the home and host pages.  Acting Project Manager/Lead Developer on the site.',
  //   // livelink: 'https://bearbnb-06.herokuapp.com',
  //   // github: 'https://github.com/chingu-voyage3/bears-06',
  //   img: 'bearbnb.JPG',
  //   fullimg: 'website-bearbnb.png'
  // },
  // mernapp: {
  //   pagename: 'mernapp',
  //   name: 'Vannya-MERN-App',
  //   description:
  //     'MERN app generator published to npm.  I found I was writing the same starter code for my projects and decided to create this to streamline my development time.  Feautures include a full Express and Node backend with keys, Google OAuth, basic routing and MongoDB database connection.  The ReactJS front end (create-react-app) includes Redux, OAuth client side routing, React Router V4 and Sass integration. No longer being maintained but may take it up again in the future.',
  //   livelink: 'https://www.npmjs.com/package/vannya-mern-app',
  //   github: 'https://github.com/vannya/vannya-mern-app',
  //   img: 'npm.jpg',
  //   fullimg: 'npm.jpg'
  // }
  // momentum: {
  //   pagename: 'momentum',
  //   name: 'Momentum Clone',
  //   description:
  //     'CURRENTLY BEING UPDATED. Clone of a popular productivity Chrome Extension using React, Firebase and external APIs.  Features include a Pomodoro timer, daily quotes, local weather, bookmarks and agenda/checklist.  The live demo is a stand alone build of the extension for demonstration purposes.',
  //   livelink: 'https://vannya.github.io/momentum-clone/',
  //   github: 'https://github.com/vannya/momentum-clone',
  //   img: 'momentumclone.JPG',
  //   fullimg: 'website-vannya-momentum.png'
  // }
  // fcc: {
  //   pagename: 'fcc',
  //   name: 'FCC Projects',
  //   description:
  //     'Portfolio site with all the projects completed during the FCC certifications. Projects include Simon and Tic Tac Toe games, a Pomodoro Timer, Calculator, Recipe Box, Markdown Previewer, a quote generator, weather app and others.  Simple UI designs to focus on the functionality of the projects.',
  //   livelink: 'https://fcc.vannya.me',
  //   github: 'https://github.com/vannya/fcc-projects',
  //   img: 'fcc.jpg',
  //   fullimg: 'fcc.jpg'
  // },
  resumeclone: {
    pagename: 'resumeclone',
    name: 'Resume Clone',
    description:
      'Resume website template using React and React Router V4.  I created this using a dummy API to simulate a backend and passed all data in as props.  I based this site on a Creative Market template using Material Design.',
    livelink: 'https://vannya.github.io/resume-clone-react/',
    github: 'https://github.com/vannya/resume-clone-react',
    img: 'resumeclone.JPG',
    fullimg: 'website-resume.png'
  }
  // turtrello: {
  //   pagename: 'turtrello',
  //   name: 'Turtrello',
  //   description:
  //     'Developed a kanban application in a 4 person remote team using React/Firebase. Clone of the popular list application Trello. Responsible for creation of header/navigation and database implementation.',
  //   livelink: 'https://ckingbailey.github.io/',
  //   github: 'https://github.com/chingu-coders/Voyage2-Turtles-01',
  //   img: 'turtrello.JPG',
  //   fullimg: 'turtrello.JPG'
  // }
};
